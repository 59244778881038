<template>
  <b-card title="Themes Table">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group v-if="showAdd">
        <div class="d-flex align-items-center">
          <b-button
              id="toggle-btn"
              v-b-modal.modal-prevent-closing
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="addNew()"
          >
            <feather-icon
                icon="PlusIcon"
                class="mr-50"
            />
            <span class="align-middle">Add New</span>
          </b-button>
        </div>
      </b-form-group>
      <b-form-group class="justify-content-end">
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength

      }"
        styleClass="vgt-table striped condensed no-outline"
    >

      <template
          slot="table-row"
          slot-scope="props"
      >

        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'index'">
         <span>{{props.row.originalIndex+1}}</span>
        </span>
        <span
            v-else-if="props.column.field === 'feature_status'"
            class="text-nowrap"
        >
            <span @click="updateFeaturedStatus(props.row.id)" style="cursor:pointer">
              <span v-if="props.row.feature_status==true"><b-badge pill variant="light-success">Active</b-badge></span>
              <span v-else> <b-badge pill variant="light-danger"> Inactive</b-badge></span>
            </span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
          <span>
              <b-button @click="edit(props.row)"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary" class="btn-icon rounded-circle mr-1"
                        v-if="showEdit"
              >
                <feather-icon
                    icon="Edit2Icon"
                />
              </b-button>
             <b-button @click="deleteType(props.row)"
                       id="delete-btn"
                       v-b-modal.modal-delete
                       v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                       variant="primary" class="btn-icon rounded-circle"
                       v-if="showDelete"
             >
                <feather-icon
                    icon="TrashIcon"
                />
              </b-button>
          </span>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        :title="getModalTitle()"
        centered
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
    >
      <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
      >

        <b-form-group
            label="Business Type"
            label-for="fh-area"
            md="6"

        >
          <v-select
              id="fh-country"
              v-model="modify.business_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="businessTypes"
              :reduce="business_type=>business_type.id"
              label="business_type"
          />
        </b-form-group>
        <b-form-group
            label-for="fh-type"
            label="Theme Type"


        >
          <v-select
              id="fh-type"
              v-model="modify.theme_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="themeType"
              :reduce="name=>name.id"
              label="name"

          />
        </b-form-group>
        <b-row>
          <b-col md="6" id="Product image 2" class="mb-2">
            <b-col class="d-flex">
              <b-img thumbnail fluid :src="product_image" alt="Theme Thumbnail"/>
              <!--              <b-button-->
              <!--                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
              <!--                  variant="outline-danger"-->
              <!--                  size="sm"-->
              <!--                  class="ml-75 h-25"-->
              <!--                  md="2"-->
              <!--                  @click="deleteImg('product_image2')"-->
              <!--              >-->
              <!--                <feather-icon icon="TrashIcon"></feather-icon>-->
              <!--              </b-button>-->
            </b-col>
          </b-col>
          <b-col md="6" id="Product image 1 upload">
            <b-form-group
                label="Theme Thumbnail "
                label-for="fh-Product1"
            >
              <b-form-file
                  id="fh-Product1"
                  accept=".jpg, .png, .gif"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                  v-model="product_image1"
                  name="jgj76"
                  @change="previewProductFile1($event)"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
            :state="nameState"
            label="Name"
            label-for="name-input"
            invalid-feedback="Name is required"
        >

          <b-form-input
              id="name-input"
              v-model="modify.name"
              :state="nameState"
              required
          />
        </b-form-group>  <b-form-group
            :state="nameState"
            label="Theme ID"
            label-for="theme-id-input"
            invalid-feedback="Theme ID is required"
        >

          <b-form-input
              id="theme-id-input"
              v-model="modify.theme_id"
              :state="nameState"
              required
          />
        </b-form-group>
        <b-form-group
            :state="nameState"
            label="Git_Link"
            label-for="git_input"
            invalid-feedback="Git Link is required"
        >

          <b-form-input
              id="git_input"
              v-model="modify.git_link"
              :state="nameState"
              required
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
        id="modal-delete"
        ref="modal-delete"
        centered
        modal-class="modal-danger"
        title="Are you sure?"
    >
      <b-card-text class="border-2">
        <span>If you delete, you won't be able to revert this action!!</span>
      </b-card-text>

      <template #modal-footer>
        <div class="w-100">


          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right"
              @click="confirmDelete()"
          >
            Accept
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-right mr-1"
              @click="$refs['modal-delete'].toggle('#delete-btn')"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>

</template>

<script>

import {
  BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
  BModal,VBModal,BCardText,
  BRow,
  BCol,
  BImg,
  BFormFile,
  BForm,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BFormCheckbox
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {host} from "@/http/APIPath";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    vSelect,
    BCardText,
    BRow,
    BCol,
    BImg,
    BFormFile,
    BForm,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      nameState: null,
      addNewMode:false,
      popupActive:true,
      modify:{
        id:null,
        theme_type: null,
        business_type: null,
        name: null,
        git_link: null,
        theme_id: null,
      },
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: '#',
          field: 'index',
          type:'number',
          tdClass: 'text-center ',
          thClass:'text-center'
        },
        {
          label: 'Title',
          field: 'name',
          tdClass: 'text-center',
          thClass:'text-center'
        },
        {
          label: 'Business Type',
          field: 'business_type.business_type',
          tdClass: 'text-center',
          thClass:'text-center'
        },
        {
          label: 'Theme Type',
          field: 'theme_type.name',
          tdClass: 'text-center',
          thClass:'text-center'
        },
        {
          label: 'Featured',
          field: 'feature_status',
          tdClass: 'text-center',
          thClass:'text-center'
        },
        {
          label: 'Theme ID',
          field: 'theme_id',
          tdClass: 'text-center',
          thClass:'text-center'
        },

      ],
      product_image: "http://via.placeholder.com/400x400",
      product_image1: "http://via.placeholder.com/400x400",
      product_image_id1: undefined,
      showAdd:true,
      showEdit:true,
      showDelete:true,
      rows: [],
      searchTerm: '',

    }
  },
  computed: {
    ...mapState('businessType', ["businessTypes","businessTypeLoading","businessTypeError"]),
    ...mapState('themeType', ["themeType","themeTypeError","themeTypeLoading"]),
    ...mapState('theme', ["theme","themeLoading","themeError", "submitError","submitSuccess","submitLoading"]),

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    theme(newValue, oldValue) {
      if (newValue) {
        this.rows=this.theme
      }
    }
  },
  created() {
    this.$store.dispatch('theme/fetchTheme')
    this.$store.dispatch('businessType/fetchBusinessTypes')
    this.$store.dispatch('themeType/fetchThemeType')
    this.rows = this.theme
    // let permissions=JSON.parse(localStorage.getItem('permissions'))
    // this.showAdd=permissions.includes("add_area")
    // this.showEdit=permissions.includes("change_area")
    // this.showDelete=permissions.includes("delete_area")
    if(this.showEdit||this.showDelete){
      this.columns.push( {
        label: 'Action',
        field: 'action',
        tdClass: 'text-center',
        thClass:'text-center',
        sortable: false,
      })

    }
  },
  methods:{
    previewProductFile1(evt) {
      const file = evt.target.files[0];
      this.product_image1 = file
      if (file) {
        this.imageRead(file).then(result => {
          this.product_image_id1 = result
          this.product_image = result
        })
      }

    },
    imageRead(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result)
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      if(this.addNewMode){
        await this.$store.dispatch('theme/addTheme',{
          'theme_type':this.modify.theme_type,
          'git_link':this.modify.git_link,
          'business_type':this.modify.business_type,
          'name':this.modify.name,
          'image':this.product_image_id1,
          'theme_id':this.modify.theme_id
        }).then(result=>{
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Success`,
              icon: 'ThumbsUpIcon',
              variant: 'success',
              text: result,
            },
          })
        })
      }else{
        this.modify.image=this.product_image_id1
        await this.$store.dispatch('theme/updateTheme',this.modify).then(result=> {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                  text: result,
                },
              })
            }
        )
      }
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    addNew () {
      this.modify.name=undefined
      this.modify.git_link=undefined
      this.modify.theme_type=undefined
      this.modify.business_type=undefined
      this.modify.theme_id=undefined
      this.addNewMode = true;
    },
    edit (data) {
      this.addNewMode=false
      this.modify.name=data.name
      this.modify.id=data.id
      this.modify.git_link=data.git_link
      this.modify.theme_type=data.theme_type.id
      this.modify.business_type=data.business_type.id
      this.modify.theme_id=data.theme_id
      this.product_image=host+data.image

      // this.popupActive=true;
    },
    initValues(){
      this.modify= {
        theme_type: null,
        business_type: null,
        name: null,
        git_link: null,
        theme_id: null,
      }
    },
    deleteType(data){
      this.modify.id=data.id
    },
    async confirmDelete(){
      await this.$store.dispatch("theme/deleteTheme", this.modify.id).then(result=>{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success`,
            icon: 'ThumbsUpIcon',
            variant: 'success',
            text: result,
          },
        })
      })
      this.$nextTick(() => {
        this.$refs['modal-delete'].toggle('#delete-btn')
      })
    },
    getModalTitle(){

      return (this.addNewMode? 'Add New ' : 'Update ') +'Theme'
    },
    updateFeaturedStatus(data) {
      console.log(data)
      this.$store.dispatch('theme/toggleThemeFeatureStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },
  }
}
</script>

<style lang="scss" >
.no-outline{
  border:none !important;
}
.f20{
  font-size: 15px !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
